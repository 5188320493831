const entityResources = {
  STAGE: {
    NAME: "stages",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "stages/upload",
    API_UPLOAD: "stages/bulk",
  },
  GRADE: {
    NAME: "grades",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "grades/upload",
    API_UPLOAD: "grades/bulk",
  },
  STUDENT: {
    NAME: "students",
    UNREGISTER: "students/unregister",
    UNLISTED: "students/unlisted",
    UNSAVING: "students/unsaving",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "students/upload",
    API_UPLOAD: "students/bulk",
    EXPORT: "students/export",
  },
  EMPLOYEE: {
    NAME: "employees",
    UNREGISTER: "employees/unregister",
    UNLISTED: "employees/unlisted",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "employees/upload",
    API_UPLOAD: "employees/bulk",
  },
  GENERATION: {
    NAME: "generations",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "generations/upload",
    API_UPLOAD: "generations/bulk",
  },
  SCHOOL_YEAR: {
    NAME: "school-years",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "school-years/upload",
    API_UPLOAD: "school-years/bulk",
  },
  SCHOOL_GUIDELINE: {
    NAME: "school-guidelines",
    UPLOAD_URL: "",
    UPLOAD_PATH: "",
    API_UPLOAD: "",
  },
  INVOICE_TYPE: {
    NAME: "invoice-types",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "invoice-types/upload",
    API_UPLOAD: "invoice-types/bulk",
  },
  SCHOOL_YEAR_STUDENT: {
    NAME: "school-year-students",
    STUDENT: "school-year-students/student",
    UNNOTED: "school-year-students/unnoted",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "school-year-students/upload",
    API_UPLOAD: "school-year-students/bulk",
  },
  SCHOOL_YEAR_EMPLOYEE: {
    NAME: "school-year-employees",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "school-year-employees/upload",
    API_UPLOAD: "school-year-employees/bulk",
  },
  SCHOOL_YEAR_STUDENT_NOTE: {
    NAME: "school-year-student-notes",
    UPLOAD_URL: "",
    UPLOAD_PATH: "",
    API_UPLOAD: "",
  },
  SCHOOL_YEAR_INVOICE: {
    NAME: "school-year-invoices",
    PAID: "school-year-invoices/paid",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "school-year-invoices/upload",
    API_UPLOAD: "school-year-invoices/bulk",
    GENERATE_URL: "generate",
    GENERATE_PATH: "school-year-invoices/generate",
    API_GENERATE: "school-year-invoices/generate",
    EXPORT: "school-year-invoices/export",
  },
  HIGHLIGHT_TYPE: {
    NAME: "highlight-types",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "highlight-types/upload",
    API_UPLOAD: "highlight-types/bulk",
  },
  SCHOOL_HIGHLIGHT: {
    NAME: "school-highlights",
    UPLOAD_URL: "",
    UPLOAD_PATH: "",
    API_UPLOAD: "",
  },
  SCHOOL_SCHEDULE: {
    NAME: "school-schedules",
    UPLOAD_URL: "",
    UPLOAD_PATH: "",
    API_UPLOAD: "",
  },
  SCHOOL_CALENDAR: {
    NAME: "school-calendars",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "school-calendars/upload",
    API_UPLOAD: "school-calendars/bulk",
  },
  NOTE_TYPE: {
    NAME: "note-types",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "note-types/upload",
    API_UPLOAD: "note-types/bulk",
  },
  USER: {
    NAME: "users",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "users/upload",
    API_UPLOAD: "users/bulk",
    API_GENERATE: "users/generate",
    RESET_PWD: "/reset-password",
  },
  ROLE: {
    NAME: "roles",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "roles/upload",
    API_UPLOAD: "roles/bulk",
  },
  IDENTITY_VERIFICATION: {
    NAME: "identity",
    OTP: "identity/otp",
    RESET_PASSWORD: "identity/reset-password",
  },
  REWARD_TYPE: {
    NAME: "reward-types",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "reward-types/upload",
    API_UPLOAD: "reward-types/bulk",
  },
  REWARD: {
    NAME: "rewards",
    UPLOAD_URL: "",
    UPLOAD_PATH: "",
    API_UPLOAD: "",
  },
  SAVING: {
    NAME: "savings",
    DEPOSIT: "savings/deposit",
    WITHDRAW: "savings/withdraw",
    UPLOAD_URL: "upload",
    UPLOAD_PATH: "savings/upload",
    API_UPLOAD: "savings/bulk",
    EXPORT: "savings/export",
  },
  SAVING_STATEMENTS: {
    NAME: "saving-statements",
  },
};

export default entityResources;
